import { Navigate, useParams } from "react-router-dom";
import Chat from "./Chat";

export default function ChatRouteWrapper() {
  const { id } = useParams();

  if (!id) {
    return <Navigate to="/messenger" />;
  }

  return <Chat id={id} />;
}
