import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Radio,
  Row,
  Segmented,
  Select,
  Slider,
  Space,
  Spin,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import InfluencerCard from "./InfluencerCard";
import FieldFilter from "components/field-filter";
import "./Influencers.scss";
import { getFoodieCategories, getInfluencers } from "services/SauceService";
import CheckboxGroup from "components/checkbox-group/CheckboxGroup";
import LocationField from "components/location-field/LocationField";
import InfluencerModal from "components/influencer-modal";
import { useSelector } from "react-redux";
import { Subscription } from "interfaces/subscription";
import { SubscriptionType } from "enums/SubscriptionType";
import { ReactComponent as CollabsEmpty } from "assets/images/collabs-empty.svg";
import { CollabsState } from "redux-context/collabs";

enum InfluencerStatus {
  PENDING = "Pending",
  ALL = "All influencers",
}

const typeFilter = [
  {
    value: "anyType",
    label: "Any type",
  },
  {
    value: "premium",
    label: "Premium",
  },
  {
    value: "basic",
    label: "Basic",
  },
];

const dietaryRestrictions = [
  { value: "vegetarian", label: "Vegetarian" },
  { value: "vegan", label: "Vegan" },
  { value: "lactoseIntolerance", label: "Lactose Intolerance" },
  { value: "glutenIntolerance", label: "Gluten Intolerance" },
  { value: "noPork", label: "No Pork" },
  { value: "nutsAllergy", label: "Nuts Allergies" },
  { value: "pescatarian", label: "Pescatarian" },
  { value: "shellfishAllergy", label: "Shellfish Allergy" },
];

const EmptyList = () => {
  return (
    <div className="onboarding-card">
      <Card style={{ padding: 40 }}>
        <Row justify="center">
          <Col md={12} xs={24} style={{ textAlign: "center" }}>
            <Row justify="center">
              <CollabsEmpty />
            </Row>
            <Typography.Title level={4}>Waiting for responses</Typography.Title>

            <Typography.Text>
              Very soon, influencer responses will appear here, and you'll be
              able to choose the most suitable one.
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const NoCollabs = () => {
  const navigate = useNavigate();
  const onClickCreateCollab = () => {
    navigate("/collabs/new");
  };
  return (
    <div className="onboarding-card">
      <Card style={{ padding: 40 }}>
        <Row justify="center">
          <Col md={12} xs={24} style={{ textAlign: "center" }}>
            <Typography.Title level={4} style={{ margin: 0 }}>
              First, create a collab
            </Typography.Title>
            <Typography.Text>
              After creating a collab, you'll see influencer responses to it
              here.
            </Typography.Text>
            <Row justify="center" style={{ marginTop: 32 }}>
              <Button type="primary" onClick={onClickCreateCollab}>
                Create collab
              </Button>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const Influencers: React.FC = () => {
  const pendingInfluencers = useLoaderData() as any;
  const [form] = Form.useForm();
  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const [selectedStatus, setSelectedStatus] = useState<string>(
    InfluencerStatus.PENDING
  );
  const [filter, setFilter] = useState({ skip: 0, limit: 24 });
  const [categories, setCategories] = useState<any[]>([]);
  const [localLoading, setLocalLoadingLoading] = useState(false);
  const [filteredInfluencers, setFilteredInfluencers] = useState<any[]>([]);
  const [venueOptions, setVenueOptions] = useState<any>([
    { value: "all", label: "All Locations" },
  ]);
  const [selectedLocation, setSelectedLocation] = useState<string>("all");
  const [influencers, setInfluencers] = useState<any>({ data: [], total: 0 });
  const [totalInfluencers, setTotalInfluencers] = useState<number>(0);
  const [selectedFoodie, setSelectedFoodie] = useState<string>("");
  const [openFoodieModal, setOpenFoodieModal] = useState<boolean>(false);
  const {
    business,
    subscription,
  }: { business: any; subscription: Subscription } = useSelector(
    (state: any) => state.business
  );
  const { numbers } = useSelector((state: CollabsState) => state.collabs);
  const freemium = subscription?.subscriptionType === SubscriptionType.Fremium;
  const showNoCollabs = useMemo(() => {
    return (numbers.reviewed || 0) === 0 && (numbers.totalCollabs || 0) === 0;
  }, [numbers]);

  const showWaitingApplications = useMemo(() => {
    return (
      (numbers.reviewed || 0) === 0 && (numbers.totalApplications || 0) === 0
    );
  }, [numbers]);

  const tabOptions = ["Pending"];
  if (!freemium) tabOptions.push("All influencers");

  const onClickFoodie = (foodieId: string) => {
    setSelectedFoodie(foodieId);
    setOpenFoodieModal(true);
  };

  const getAllInfluencers = useCallback(async () => {
    setLocalLoadingLoading(true);
    const response: any = await getInfluencers(filter);
    setInfluencers((prev: any) => ({
      ...prev,
      ...response,
      data: [...prev.data, ...response.data],
    }));
    setLocalLoadingLoading(false);
  }, [filter]);

  const onChangeStatus = (status: string) => {
    setSelectedStatus(status);
  };

  const onFinish = (values: any) => {
    setInfluencers({ data: [], total: 0 });
    setFilter({ ...filter, ...values, skip: 0 });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const response: any = await getFoodieCategories();
      setCategories(
        response.map((item: any) => ({ value: item, label: item }))
      );
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    getAllInfluencers();
  }, [getAllInfluencers]);

  useEffect(() => {
    if (selectedStatus === InfluencerStatus.PENDING) {
      const influencersFiltered = pendingInfluencers.filter((collab: any) => {
        const isAllLocations = selectedLocation === "all";

        const isMatchingVenue = collab.venue.uuid === selectedLocation;

        if (isAllLocations || isMatchingVenue) return true;

        return false;
      });
      setFilteredInfluencers(influencersFiltered);
      setTotalInfluencers(pendingInfluencers.length);
    }
    if (selectedStatus === InfluencerStatus.ALL) {
      setFilteredInfluencers(influencers.data);
      setTotalInfluencers(influencers.total);
    }
  }, [selectedStatus, influencers, pendingInfluencers, selectedLocation]);

  useEffect(() => {
    const handleScroll = () => {
      if (selectedStatus === InfluencerStatus.PENDING) return;
      const nearBottom =
        Math.abs(
          window.innerHeight +
            document.documentElement.scrollTop -
            document.documentElement.offsetHeight
        ) < 10;
      if (nearBottom && !localLoading) {
        setFilter((prevFilter) => ({
          ...prevFilter,
          skip: prevFilter.skip + prevFilter.limit,
        }));
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [localLoading, filter, selectedStatus]);

  useEffect(() => {
    if (business) {
      const businessVenue =
        business.venues?.map((venue: any) => ({
          value: venue.uuid,
          label: venue.name,
        })) || [];
      setVenueOptions([
        { value: "all", label: "All Locations" },
        ...businessVenue,
      ]);
    }
  }, [business]);

  // useEffect(() => {
  //   if (pendingInfluencers.length === 0 && !freemium) {
  //     setSelectedStatus(InfluencerStatus.ALL);
  //   }
  // }, [pendingInfluencers, freemium]);

  if (loading) return <Spin />;

  // if (isMobile)
  //   return (
  //     <div style={{ textAlign: "center", marginTop: 80 }}>
  //       <Typography.Text style={{ fontSize: 16 }}>
  //         This feature can be accessible on the web version.
  //       </Typography.Text>
  //     </div>
  //   );

  // if (pendingInfluencers.length === 0 && freemium)

  if (showNoCollabs)
    return (
      <div className="influencers">
        <h1 className="header-1">Influencers</h1>
        <NoCollabs />
      </div>
    );

  if (showWaitingApplications)
    return (
      <div className="influencers">
        <h1 className="header-1">Influencers</h1>
        <EmptyList />
      </div>
    );

  return (
    <div className="influencers">
      <h1 className="header-1">Influencers</h1>
      <Row justify="space-between">
        <Segmented
          options={tabOptions}
          value={selectedStatus}
          onChange={onChangeStatus}
          style={{ marginBottom: 32 }}
        />
        <div style={{ marginBottom: 32 }}>
          <InfluencerModal
            open={openFoodieModal}
            onCancel={() => setOpenFoodieModal(false)}
            foodieId={selectedFoodie}
          />
        </div>
        <div
          style={{
            display:
              selectedStatus === InfluencerStatus.PENDING ? "block" : "none",
          }}
        >
          <Select
            variant="borderless"
            value={selectedLocation}
            options={venueOptions}
            onChange={(value) => setSelectedLocation(value)}
            popupMatchSelectWidth={false}
          />
        </div>
      </Row>
      {selectedStatus === InfluencerStatus.ALL && (
        <>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <div
              style={{
                display: "flex",
                gap: 8,
                marginBottom: 40,
                alignItems: "center",
              }}
            >
              <FieldFilter
                name="type"
                label="Type"
                valueFunction={(value: any) => {
                  const selected = typeFilter.find(
                    (item) => item.value === value
                  );
                  return selected ? selected.label : "Any type";
                }}
              >
                <Radio.Group>
                  <Space direction="vertical">
                    {typeFilter.map((item) => (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </FieldFilter>
              <FieldFilter
                name="minAmountFollowers"
                label="Min followers"
                valueFunction={(value: any) => `${value}+ followers`}
              >
                <InputNumber placeholder="Enter a number" />
              </FieldFilter>
              <FieldFilter
                name="engagementRate"
                label="Engagement"
                valueFunction={(value: any) => `${value}% engagement`}
                formLabel="Select min engagement"
                transformValue={(value: any) => {
                  if (value !== 0) return value;
                }}
              >
                <Slider
                  min={0}
                  max={8}
                  style={{ width: 400 }}
                  tooltip={{
                    formatter: (value: any) => `${value ? value : "Any"}`,
                  }}
                  marks={{
                    0: "Any",
                    1: "1",
                    2: "2",
                    3: "3",
                    4: "4",
                    5: "5",
                    6: "6",
                    7: "7",
                    8: "8",
                  }}
                />
              </FieldFilter>
              <FieldFilter
                name="rating"
                label="Rating"
                valueFunction={(value: any) => `${value}+ rate`}
                formLabel="Select min rate"
                transformValue={(value: any) => {
                  if (value > 0) return value;
                }}
              >
                <Slider
                  min={40}
                  step={10}
                  max={90}
                  style={{ width: 400 }}
                  tooltip={{
                    formatter: (value: any) => `${value ? value : "Any"}`,
                  }}
                  marks={{
                    40: "Any",
                    50: "50",
                    60: "60",
                    70: "70",
                    80: "80",
                    90: "90",
                  }}
                />
              </FieldFilter>
              <FieldFilter
                name="categories"
                label="Categories"
                valueFunction={(value: any) =>
                  value.includes("all") ? "All" : `${value.length} categories`
                }
              >
                <CheckboxGroup
                  options={categories}
                  allValue="all"
                  allLabel="All Categories"
                />
              </FieldFilter>
              <FieldFilter
                name="dietaryRestrictions"
                label="Allergies"
                valueFunction={(value: any) =>
                  `${value.length} items allergies free`
                }
              >
                <CheckboxGroup
                  options={dietaryRestrictions}
                  allValue="none"
                  allLabel="No preference"
                />
              </FieldFilter>
              <FieldFilter
                name="location"
                label="Location"
                valueFunction={(value: any) => value?.name}
              >
                <LocationField />
              </FieldFilter>
              {/* <Form.Item shouldUpdate noStyle>
             {() => {
               const values = form.getFieldsValue();
               const sanitizedValues = Object.fromEntries(
                 Object.entries(values).filter(
                   ([_, value]) => value !== undefined
                 )
               );
               const hasProps = Object.keys(sanitizedValues).length > 0;
               if (!hasProps) return null;
               return (
                 <button
                   type="button"
                   className="button-clear"
                   onClick={() => {
                     form.resetFields();
                     form.submit();
                   }}
                 >
                   Clear all
                 </button>
               );
             }}
           </Form.Item> */}
              <button
                type="button"
                className="button-clear"
                onClick={() => {
                  form.resetFields();
                  form.submit();
                }}
              >
                Clear all
              </button>
            </div>
          </Form>
          <Row style={{ marginBottom: 40 }}>
            <Typography.Text>
              {totalInfluencers} influencers found
            </Typography.Text>
          </Row>
        </>
      )}
      <Row gutter={[16, 16]} style={{ gap: 16 }}>
        {filteredInfluencers.map((influencer: any) => (
          <InfluencerCard
            influencer={influencer}
            onInfluencerClick={() => onClickFoodie(influencer.foodieId)}
            key={`INFLUENCER_${influencer.foodieId}_${
              influencer.collabId
            }_${Math.random().toString(36).substring(2, 9)}`}
          />
        ))}
      </Row>

      {localLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 40,
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
};

export default Influencers;
