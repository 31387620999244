import { SendOutlined } from "@ant-design/icons";
import { Avatar, Button, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { BusinessMessage } from "./components/BusinessMessage";
import { FoodieMessage } from "./components/FoodieMessage";
import { MustardMessage } from "./components/MustardMessage";
import { useCallback, useEffect, useRef, useState } from "react";
import { getChat, markChatAsRead, sendMessage } from "services/SauceService";
import { UserState } from "redux-context/user";
import { useSelector } from "react-redux";
import { Chat as ChatType } from "enums/ChatType";
import "./Chat.scss";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { sseService } from "services/SSEStream";
import { isMobile } from "react-device-detect";
import { DomainState } from "redux-context/domain";

export const getParticipantsByType = (chat: ChatType) => {
  const foodie = chat.participants.find(
    (participant) => participant.participantType === "foodie"
  );
  const business = chat.participants.find(
    (participant) => participant.participantType === "business"
  );

  return {
    foodie,
    business,
  };
};

const createDummyMessage = ({
  content,
  loggedUser,
  senderType,
}: {
  content: string;
  loggedUser: any;
  senderType: "business" | "mustard" | "mustard-on-behalf-of-business";
}) => {
  return {
    content,
    messageType: "text",
    state: "sending",
    senderType,
    sender: {
      _id: loggedUser._id,
      name: senderType === "mustard" ? "Mustard" : loggedUser.name,
      avatarUrl:
        senderType === "mustard"
          ? "https://app-collabs.mustard.love/logo192.png"
          : loggedUser.avatarUrl,
    },
    _id: Date.now().toString(),
    createdAt: new Date(),
  };
};

export default function Chat({ id }: { id: string }) {
  const businessId = localStorage.getItem("businessId");
  const { metadata } = useSelector((state: DomainState) => state.domain);
  const [chat, setChat] = useState<ChatType | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const { loggedUser } = useSelector((state: UserState) => state.user);
  const isAdminUser = loggedUser?.email?.endsWith(`@${metadata?.domain}`);
  const [senderType, setSenderType] = useState<
    "mustard" | "mustard-on-behalf-of-business" | "business"
  >();

  const newMessageSubscribeRef = useRef<() => void>();

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    }, 0);
  }, [messagesContainerRef]);

  useEffect(() => {
    if (!id) return;
    scrollToBottom();
  }, [id, scrollToBottom]);

  const markAsRead = useCallback(async () => {
    if (!id || !businessId) return;

    try {
      console.log("marking chat as read");
      await markChatAsRead(id, businessId);
    } catch (error) {
      //ignore
    }
  }, [id, businessId]);

  useEffect(() => {
    newMessageSubscribeRef.current = sseService.subscribe(
      "chat/new-message",
      (event) => {
        console.log("chat/new-message");
        const message = JSON.parse(event.data) as ChatType["messages"][0];

        if (message.chatId !== id) {
          console.log("skipping message");
          return;
        }

        setChat((prevChat) => {
          if (!prevChat) return prevChat;

          return {
            ...prevChat,
            messages: [...prevChat.messages, message],
          };
        });

        scrollToBottom();
        markAsRead();
      }
    );

    return () => {
      newMessageSubscribeRef.current?.();
    };
  }, [id, markAsRead, scrollToBottom]);

  useEffect(() => {
    if (isAdminUser === undefined || senderType) return;

    if (isAdminUser) {
      setSenderType("mustard-on-behalf-of-business");
    } else {
      setSenderType("business");
    }
  }, [isAdminUser, businessId, senderType]);

  const businessParticipant = chat?.participants.find(
    (participant) => participant.participantId === businessId
  );
  useEffect(() => {
    const abortController = new AbortController();
    if (id && businessId) {
      setLoading(true);
      getChat(id, businessId, abortController)
        .then((res) => {
          //@ts-ignore
          setChat(res);
        })
        .then(() => {
          scrollToBottom();
          markAsRead();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      abortController.abort();
    };
  }, [businessId, id, markAsRead, scrollToBottom]);

  const handleSendMessage = async () => {
    if (!chat || !message || isSendingMessage || message.trim().length === 0)
      return;

    const originalMessage = message;
    setMessage("");

    try {
      setIsSendingMessage(true);

      setChat((prevChat) => {
        if (!prevChat) return prevChat;

        return {
          ...prevChat,
          messages: [
            ...prevChat.messages,
            createDummyMessage({
              content: originalMessage,
              loggedUser: businessParticipant,
              senderType: senderType ?? "business",
            }) as ChatType["messages"][number],
          ],
        };
      });

      scrollToBottom();

      if (!businessParticipant?.participantId || !businessId || !senderType) {
        return;
      }

      const messageFromServer = await sendMessage(chat._id, {
        message: originalMessage,
        senderType,
        sauceUserId: loggedUser?.id,
        businessId,
      });

      setChat({
        ...chat,
        //@ts-ignore
        messages: [...chat.messages, messageFromServer],
      });

      scrollToBottom();
    } catch (error) {
      console.error(error);
      setChat({
        ...chat,
        messages: [
          ...chat.messages,
          {
            ...(createDummyMessage({
              content: originalMessage,
              loggedUser: businessParticipant,
              senderType: senderType ?? "business",
            }) as ChatType["messages"][number]),
            state: "error",
          },
        ],
      });
    } finally {
      setIsSendingMessage(false);
    }
  };

  if (!chat || loading) {
    return (
      <div className={`chat ${isMobile ? "--mobile" : ""} --loading`}>
        <Spin />
      </div>
    );
  }

  const { foodie, business } = getParticipantsByType(chat);

  return (
    <div className={`chat ${isMobile ? "--mobile" : ""}`}>
      <div className="chat__card">
        <div className="chat__header">
          <div className="chat__header-content">
            <div className="chat__header-avatars">
              <Avatar src={foodie?.avatarUrl} size={isMobile ? 32 : 40} />
              <Avatar
                src={business?.avatarUrl}
                size={isMobile ? 32 : 40}
                style={{ marginLeft: -10 }}
              />
            </div>
            <div>
              <div className="chat__header-names">
                <div>
                  <span className="chat__header-name">
                    {foodie?.participantName}{" "}
                    <span style={{ fontWeight: 400 }}>and</span>{" "}
                    {business?.participantName}
                  </span>
                </div>
                <div>
                  {chat.context.booking?.bookDate ? (
                    <>
                      ·{" "}
                      {new Date(
                        chat.context.booking.bookDate
                      ).toLocaleDateString()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="chat__header__context-info">
                <span className="chat__header__context-item">
                  <LocationIcon className="icon" />
                  <span>{chat.context.venue?.address ?? "-"}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="chat__messages" ref={messagesContainerRef}>
          <div className="chat__messages-container">
            <div>
              {chat.messages.map((message) => (
                <>
                  {message.senderType === "foodie" && (
                    <FoodieMessage key={message._id} {...message} />
                  )}
                  {message.senderType === "business" && (
                    <BusinessMessage key={message._id} {...message} />
                  )}
                  {message.senderType === "mustard-on-behalf-of-business" && (
                    <BusinessMessage key={message._id} {...message} />
                  )}
                  {message.senderType === "mustard" && (
                    <MustardMessage key={message._id} {...message} />
                  )}
                  {![
                    "foodie",
                    "business",
                    "mustard",
                    "mustard-on-behalf-of-business",
                  ].includes(message.senderType) && (
                    <>
                      <div>Type: {message.senderType}</div>
                      <div className="card">{message.content}</div>
                    </>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="chat__input-container">
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message here..."
            className="chat__input-textarea"
          />
          <div className="chat__input-actions">
            <div className="chat__input-buttons">
              <Button
                type="primary"
                className="chat__input-send"
                disabled={isSendingMessage}
                onClick={handleSendMessage}
              >
                <SendOutlined className="chat__input-send-icon" />
              </Button>
              {isAdminUser && (
                <Select
                  labelInValue={false}
                  suffixIcon={null}
                  className="chat__input-select"
                  dropdownClassName="chat__input-select-dropdown"
                  value={senderType}
                  onChange={(value) => setSenderType(value)}
                  options={[
                    {
                      label: <Avatar src={businessParticipant?.avatarUrl} />,
                      value: "mustard-on-behalf-of-business",
                    },
                    {
                      label: <Avatar src={metadata?.logoUrl} />,
                      value: "mustard",
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
