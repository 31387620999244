import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { SendMessageResponse } from "enums/ChatType";
import { GetChatsResponse } from "enums/ChatType";
import { GetChatResponse } from "enums/ChatType";
import { SendMessageRequest } from "enums/ChatType";
import { logout } from "helpers/auth-functions";

const { REACT_APP_API_URL, REACT_APP_API_KEY } = process.env;

export const instance = axios.create({
  baseURL: REACT_APP_API_URL,
});

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (
    config.url?.startsWith("reports") ||
    config.url?.startsWith("business/onboarding") ||
    config.url?.startsWith("payment/generate-payment-link") ||
    config.url?.startsWith("sales/save-partial") ||
    config.url?.startsWith("info/brand-metadata")
  ) {
    config.headers["x-api-key"] = REACT_APP_API_KEY;
  } else {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response.data;
  },
  (error: any) => {
    if (axios.isCancel(error)) {
      console.log("Requisição cancelada:", error.message);
    } else if (error) {
      switch (error.response.status) {
        case 401:
        case 403:
          if (window.location.pathname !== "/login")
            window.location.href = "/login";
          logout();
          break;
      }
    }
    return Promise.reject(error);
  }
);

export const postSignIn = (params: any) => {
  return instance.post(`auth/signin`, params);
};

export const getUserLoggedInfo = () => {
  return instance.post(`auth/user-logged-info`);
};

export const sendVerificationCode = (params: any) => {
  return instance.post(`auth/send-verification-code`, params);
};

export const postVerifyCode = (params: any) => {
  return instance.post(`auth/verify-code`, params);
};

export const getBusinessesLoggedUser = () => {
  return instance.post(`business/get-businesses-logged-user`);
};

export const sendForgotPasswordEmail = (params: any) => {
  return instance.post(`auth/forgot-password-send-code`, params);
};

export const sendForgotPasswordNewPassword = (params: any) => {
  return instance.post(`auth/forgot-password-new-password`, params);
};

export const getBusinessById = async (businessId: string) => {
  return await instance.get(`business/${businessId}`);
};

export const getBrandMetadata = () => {
  return instance.get(`info/brand-metadata`);
};

export const postAddVenue = (params: any) => {
  return instance.post(`business/add-venue`, params);
};

export const updateBusinessInfo = (params: any) => {
  return instance.post(`business/update-info`, params);
};

export const updateVenue = (params: any) => {
  return instance.post(`business/update-venue`, params);
};

export const uploadLogo = (params: any) => {
  return instance.post(`business/upload-logo`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const removeLogo = (params: any) => {
  return instance.delete(`business/remove-logo`, {
    data: params,
  });
};

export const getSubscriptionInfo = (data: any) => {
  return instance.post(`business/subscription/get`, data);
};

export const postSocialMediaInstructions = (params: any) => {
  return instance.post(`business/social-media-instructions`, params);
};

export const fetchCollabs = (params: any) => {
  return instance.post(`collabs/search-business-collab`, params);
};

export const getCollabById = (collabId: string) => {
  return instance.get(`collabs/business/${collabId}`);
};

export const connectSocialMedia = (params: any) => {
  return instance.post(`business/connect-social-media`, params);
};

export const createBusinessCollab = (params: any) => {
  return instance.post(`collabs/create-business-collab`, params);
};

export const searchBusinessApplications = (params: any) => {
  return instance.post(`collabs/search-business-applications`, params);
};

export const searchBusinessApplicationsInbox = (params: any) => {
  return instance.post(`collabs/business-applications-inbox`, params);
};

export const reviewBusinessApplication = (params: any) => {
  return instance.post(`collabs/review-business-application`, params);
};

export const searchInvitedBusiness = (params: any) => {
  return instance.post(`collabs/search-invited-business`, params);
};

export const rescheduleBusinessApplication = (params: any) => {
  return instance.post(`collabs/reschedule-business-application`, params);
};

export const rescheduleApplicationTargetTime = (params: any) => {
  return instance.post(`collabs/reschedule-application-target-time`, params);
};

export const rescheduleApplicationSuggestedDates = (params: any) => {
  return instance.post(
    `collabs/reschedule-application-suggested-dates`,
    params
  );
};

export const fetchPendingCollabsCount = (params: any) => {
  return instance.post(`collabs/business-applications-count`, params);
};

export const getPlacesByName = (params: any) => {
  return instance.post(`maps/search-places-by-name`, params);
};

export const getPlaceById = (placeId: string) => {
  return instance.get(`maps/${placeId}`);
};

export const getFoodieCategories = () => {
  return instance.get(`info/foodie-categories`);
};

export const getInfluencers = (params: any) => {
  return instance.post(`influencer/list-filtered`, params);
};

export const getFoodieById = (foodieId: string) => {
  return instance.get(`user/get-by-id/${foodieId}`);
};

export const updatePassword = (params: any) => {
  return instance.post(`auth/update-password`, params);
};

export const getCollabsStats = (params: any) => {
  return instance.post(`reports/collabs-stats`, params);
};

export const getCollabsStatsByDate = (params: any) => {
  return instance.post(`reports/comments-stats`, params);
};

export const getSocialMediaStats = (params: any) => {
  return instance.post(`reports/social-media-stats`, params);
};

export const chargeAdditionalInfluencerCredits = (params: any) => {
  return instance.post(`payment/charge-additional-influencer-credits`, params);
};

export const generatePaymentLink = (params: any) => {
  return instance.post(`payment/generate-payment-link`, params);
};

export const retrieveUserSocialMedia = (params: any) => {
  return instance.post(`user/retrieve-user-social-media`, params);
};

export const postFinishOnboarding = (params: any) => {
  return instance.post(`business/onboarding/finish`, params);
};

export const postPartialSaveOnboarding = (params: any) => {
  return instance.post(`sales/save-partial`, params);
};

export const getChats = ({ businessId }: { businessId: string }) => {
  return instance.get<GetChatsResponse>(`chats`, {
    params: {
      businessId,
    },
  });
};

export const getChat = (
  id: string,
  businessId: string,
  abortController?: AbortController
) => {
  return instance.get<GetChatResponse>(`chats/${id}`, {
    params: {
      businessId,
    },
    signal: abortController?.signal,
  });
};

export const sendMessage = (id: string, data: SendMessageRequest) => {
  return instance.post<SendMessageResponse>(
    `chats/${id}/custom-messages`,
    data
  );
};

export const markChatAsRead = (chatId: string, businessId: string) => {
  return instance.post(`chats/${chatId}/read`, {
    params: {
      businessId,
    },
  });
};
