import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Typography,
  message,
} from "antd";
import { Venue } from "interfaces/venue";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";
import {
  CloseOutlined,
  DeleteOutlined,
  InstagramOutlined,
  PlusCircleOutlined,
  TikTokOutlined,
} from "@ant-design/icons";
import { debounce } from "lodash";
import { getPlacesByName, updateVenue } from "services/SauceService";
import WeekHoursField from "pages/collab/WeekHoursField";
import { CollabAvailability } from "pages/collab/CollabDetail";
import { BusinessState, getBusinessInfo } from "redux-context/business";
import { useWatch } from "antd/es/form/Form";
import { isMobile } from "react-device-detect";

const Locations: React.FC = () => {
  const { business } = useSelector((state: BusinessState) => state.business);
  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();
  const venues = business?.venues || [];
  const [selectedVenue, setSelectedVenue] = useState<Venue>();
  const [places, setPlaces] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [editing, setEditing] = useState<boolean>(false);
  const [loadingLocation, setLoadingLocation] = useState<boolean>(false);
  const googlePlaceId = useWatch("googlePlaceId", form);

  const getLocation = useCallback(async (locationName: string) => {
    setLoadingLocation(true);
    const response: any = await getPlacesByName({ name: locationName });
    setPlaces(
      response.map((place: any) => ({
        value: place.place_id,
        label: place.name,
        ...place,
      }))
    );
    setLoadingLocation(false);
  }, []);

  const handleSearch = (newValue: string) => {
    if (newValue) getLocation(newValue);
  };

  const handleChange = (_: string, option: any) => {
    form.setFieldValue("addressDisplay", option.formatted_address);
    form.setFieldValue("name", option.name);
  };

  const onClickSelectedVenue = (venue: Venue) => {
    setSelectedVenue(venue);
  };

  const onEditClick = () => {
    setEditing(true);
  };

  const onCancelEditClick = () => {
    setEditing(false);
    form.resetFields();
  };

  const onCancelClick = () => {
    setSelectedVenue(undefined);
    setEditing(false);
  };

  const onDeleteClick = async () => {
    Modal.confirm({
      content: "Are you sure you want to delete this venue?",
      icon: "",
      onOk: async () => {
        if (selectedVenue) {
          try {
            setLoading(true);
            // await deleteCatalogMigration(migrationId);
            message.success("Venue removed successfully");
            dispatch(getBusinessInfo());
          } catch {
            message.error("Failed to remove venue");
          } finally {
            setLoading(false);
          }
        } else {
          message.warning("No venue selected to delete");
        }
      },
      okButtonProps: {
        type: "primary",
      },
    });
  };

  const onFinish = async () => {
    const save = async () => {
      setLoading(true);
      const businessId = business._id;
      const values = form.getFieldsValue(true);
      const params = {
        businessId,
        venueId: selectedVenue?._id,
        ...values,
      };
      try {
        const response: any = await updateVenue(params);
        if (response.message) {
          dispatch(getBusinessInfo());
          message.success("Venue saved successfully");

          setEditing(false);
          setSelectedVenue(undefined);
        }
      } catch (error) {
        console.error(error);
        message.error("Failed to save venue");
      }
      setLoading(false);
    };

    Modal.confirm({
      title:
        "IMPORTANT: Please note that the changes will only affect new collaborations. All existing collaborations with this location will retain the old information.",
      icon: "",
      onOk: async () => {
        save();
      },
      okButtonProps: {
        type: "primary",
      },
    });
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedVenue, form, editing]);

  useEffect(() => {
    if (selectedVenue)
      setPlaces([
        {
          value: selectedVenue?.googlePlaceId,
          label: selectedVenue?.name,
          name: selectedVenue?.name,
          formatted_address: selectedVenue?.addressDisplay,
        },
      ]);
  }, [selectedVenue]);

  if (editing)
    return (
      <Card className="locations edit-form">
        <Form
          form={form}
          layout="vertical"
          initialValues={selectedVenue}
          onFinish={onFinish}
          style={{ minWidth: 450 }}
        >
          <Typography.Title
            level={4}
            style={{ paddingBottom: 8, display: "block" }}
          >
            Point of contact
          </Typography.Title>
          <Form.Item label="Find location" name="googlePlaceId">
            <Select
              placeholder="Typing the name and address"
              showSearch
              filterOption={false}
              onSearch={debounce(handleSearch, 500)}
              onSelect={handleChange}
              options={places}
              loading={loadingLocation}
              allowClear
              optionRender={(option: any) => {
                return (
                  <>
                    <div style={{ padding: "8px", cursor: "pointer" }}>
                      {`${option.data.name} - ${option.data.formatted_address}`}
                    </div>
                  </>
                );
              }}
            />
          </Form.Item>

          <Form.Item label="Name" name="name" hidden={!googlePlaceId} noStyle>
            <Input placeholder="Name of the location" />
          </Form.Item>

          <div
            style={{
              backgroundColor: "#F9F5ED",
              marginBottom: 24,
              height: 40,
              marginTop: -4,
              borderRadius: "0 0 6px 6px",
              display: googlePlaceId ? "flex" : "none",
              alignItems: "center",
              border: "1px solid #E6E6E6",
              padding: "8px 16px",
              gap: 8,
            }}
          >
            <Form.Item name="ghostKitchen" noStyle>
              <Switch />
            </Form.Item>
            Ghost kitchen
          </div>

          <Typography.Text style={{ paddingBottom: 8, display: "block" }}>
            Point of contact
          </Typography.Text>
          <Form.List name="pocs">
            {(fields, { add, remove }) => (
              <div
                style={{
                  display: "flex",
                  rowGap: 16,
                  flexDirection: "column",
                  marginBottom: 32,
                }}
              >
                {fields.map((field, index) => (
                  <div key={`poc_${field.name}`}>
                    {index > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginBottom: 8,
                        }}
                      >
                        <CloseOutlined
                          onClick={() => {
                            remove(field.name);
                          }}
                        />
                      </div>
                    )}
                    <Form.Item name={[field.name, "name"]}>
                      <Input placeholder="First name" />
                    </Form.Item>
                    <Form.Item name={[field.name, "email"]}>
                      <Input type="email" placeholder="Email" />
                    </Form.Item>
                    <Form.Item name={[field.name, "phone"]} noStyle>
                      <Input placeholder="Phone number" />
                    </Form.Item>
                  </div>
                ))}

                <span
                  style={{ color: "#1D2023", cursor: "pointer" }}
                  onClick={() => add()}
                >
                  <PlusCircleOutlined style={{ marginRight: 4 }} />
                  Add another POC
                </span>
              </div>
            )}
          </Form.List>
          <Form.Item label="Links to social media" name="instagramUrl">
            <Input placeholder="Instagram" prefix={<InstagramOutlined />} />
          </Form.Item>
          <Form.Item name="tiktokUrl">
            <Input placeholder="TikTok" prefix={<TikTokOutlined />} />
          </Form.Item>
          <Typography.Text style={{ paddingBottom: 8, display: "block" }}>
            Working Hours
          </Typography.Text>
          <WeekHoursField name="venueWorkingHours" form={form} />
          <Row style={{ gap: 12 }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
            <Button htmlType="button" type="text" onClick={onCancelEditClick}>
              Cancel
            </Button>
          </Row>
        </Form>
      </Card>
    );

  if (selectedVenue)
    return (
      <Card className="locations">
        <Row justify="center" gutter={[20, 20]}>
          <Col xs={8}>
            <Typography.Text>Name</Typography.Text>
          </Col>
          <Col xs={16}>{selectedVenue.name}</Col>
          <Col xs={8}>
            <Typography.Text>Address</Typography.Text>
          </Col>
          <Col xs={16}>{selectedVenue.addressDisplay}</Col>
          <Col xs={8}>
            <Typography.Text>Point of contact</Typography.Text>
          </Col>
          <Col xs={16}>
            {selectedVenue?.pocs?.map((pointOfContact) => (
              <div
                key={`POINT_OF_CONTACT_${pointOfContact._id}_${pointOfContact.email}`}
                className="poc-description"
              >
                <span className="name">{pointOfContact.name}</span> {" ∘ "}
                <span>{pointOfContact.phone}</span>
                {" ∘ "}
                <span>{pointOfContact.email}</span>
              </div>
            ))}
          </Col>
          <Col xs={8}>
            <Typography.Text>Links to social media</Typography.Text>
          </Col>
          <Col xs={16}>
            <div className="link-social">
              <InstagramOutlined />{" "}
              {selectedVenue.instagramUrl?.includes("http") ? (
                <a
                  href={selectedVenue.instagramUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedVenue.instagramUrl}
                </a>
              ) : (
                selectedVenue.instagramUrl
              )}
            </div>
            <div className="link-social">
              <TikTokOutlined />{" "}
              {selectedVenue.tiktokUrl?.includes("http") ? (
                <a
                  href={selectedVenue.tiktokUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {selectedVenue.tiktokUrl}
                </a>
              ) : (
                selectedVenue.tiktokUrl
              )}
            </div>
          </Col>
          <Col xs={8}>
            <Typography.Text>Visiting hours</Typography.Text>
          </Col>
          <Col xs={16}>
            <Row gutter={[14, 14]}>
              {selectedVenue?.venueWorkingHours?.map((availability, index) => (
                <CollabAvailability
                  availability={availability}
                  key={`COLLAB_AVAILABILITY${index}`}
                />
              ))}
            </Row>
          </Col>
        </Row>
        <Row justify="end" style={{ gap: 8 }}>
          <Button icon={<EditIcon />} onClick={onEditClick}>
            Edit
          </Button>
          <Button
            style={{ display: "none" }} // TODO: Remove this line}
            htmlType="button"
            icon={<DeleteOutlined style={{ color: "#FF354A" }} />}
            onClick={onDeleteClick}
          />
          <Button htmlType="button" type="text" onClick={onCancelClick}>
            Cancel
          </Button>
        </Row>
      </Card>
    );

  return (
    <Row gutter={[12, 12]} className="locations">
      {venues.map((location: Venue) => (
        <Col xs={24} key={location.uuid}>
          <Card>
            <Row>
              <Col xs={24} md={14}>
                <div className="content">
                  <Avatar
                    src={<LocationIcon style={{ width: 20, height: 20 }} />}
                    size="large"
                    style={{ backgroundColor: "#ffac39", minWidth: 40 }}
                  />
                  <div>
                    <span
                      className="label-strong"
                      style={{ fontSize: isMobile ? 16 : "unset" }}
                    >
                      {location.name}
                    </span>
                    <span
                      className="text-regular-small"
                      style={{ display: "block" }}
                    >
                      {location.addressDisplay}
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={24} md={10}>
                <div className="pocs">
                  <div>
                    {location.pocs?.length > 0 && (
                      <div>
                        <span className="name">{location.pocs[0].name}</span>
                        {location.pocs?.length > 1 && (
                          <span className="other">
                            + {location.pocs.length - 1} POC
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  <Button
                    icon={<EditIcon />}
                    onClick={() => onClickSelectedVenue(location)}
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default Locations;
