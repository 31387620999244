import { Avatar } from "antd";
import { Chat } from "enums/ChatType";
import "./MustardMessage.scss";
import { useSelector } from "react-redux";
import { DomainState } from "redux-context/domain";

export const MustardMessage = ({
  content,
  state,
  sender,
}: Chat["messages"][number]) => {
  const { metadata } = useSelector((state: DomainState) => state.domain);

  return (
    <div className="mustard-message">
      <div>
        <Avatar src={metadata?.smallLogoUrl || metadata?.logoUrl} />
      </div>
      <div className="mustard-message__content">
        {content}
        <div className="mustard-message__sender">
          {metadata.whiteLabel ? "Support" : sender.name}
        </div>
      </div>
    </div>
  );
};
