import { Outlet, useLocation } from "react-router-dom";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import {
  getBusinessInfo,
  getBusinessSubscription,
  getBusinessess,
} from "redux-context/business";
import { autenticado } from "helpers/auth-functions";
import { getLoggedUserInfo } from "redux-context/user";
import { getPendingCollabsCount } from "redux-context/collabs";
import * as Sentry from "@sentry/react";
import { sseService } from "services/SSEStream";
import { getAllChats, setUnreadMessageCount } from "redux-context/chat";
import { getDomainMetadata } from "redux-context/domain";

// import * as rdd from "react-device-detect";

// (rdd as any).isMobile = true;
// (rdd as any).isDesktop = false;

const { REACT_APP_SENTRY_DSN } = process.env;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

function App() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const { loading } = useSelector((state: any) => state.user);

  const newUnreadMessageSubscribeRef = useRef<() => void>();
  const businessId = localStorage.getItem("businessId");

  useEffect(() => {
    newUnreadMessageSubscribeRef.current = sseService.subscribe(
      "chat/total-unread-count",
      (event) => {
        console.log("chat/total-unread-count");
        const unreadCount = JSON.parse(event.data) as number;
        dispatch(setUnreadMessageCount(unreadCount));
      }
    );

    dispatch(getAllChats());

    return () => {
      newUnreadMessageSubscribeRef.current?.();
    };
  }, [dispatch, businessId]);

  useEffect(() => {
    if (autenticado() && loading) {
      dispatch(getLoggedUserInfo());
    }

    if (!loading) {
      dispatch(getBusinessess());
      dispatch(getBusinessInfo());
      dispatch(getPendingCollabsCount());
      dispatch(getAllChats());
    }
  }, [dispatch, loading]);

  useEffect(() => {
    dispatch(getBusinessSubscription());
    dispatch(getPendingCollabsCount());
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(getDomainMetadata());
  }, [dispatch]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
