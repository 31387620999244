import { Col } from "antd/es/grid";
import { Avatar, Badge, Row } from "antd";
import { Chat } from "enums/ChatType";
import { CrownOutlined } from "@ant-design/icons";
import { ReactComponent as LocationIcon } from "assets/images/location-icon.svg";

import "./RowMessage.scss";

export const RowMessage = ({
  chat,
  onClick,
  isActive,
}: {
  chat: Chat;
  onClick: () => void;
  isActive: boolean;
}) => {
  const businessId = localStorage.getItem("businessId");
  const otherParticipants = chat.participants.filter(
    (participant) => participant.participantId !== businessId
  );

  const participant = otherParticipants[0];

  if (!participant) {
    return null;
  }

  const lastMessage = chat.messages[chat.messages.length - 1];

  return (
    <div
      className={`row-message ${chat.unreadCount === 0 ? "faded" : ""} ${
        isActive ? "active" : ""
      }`}
    >
      <Row className={`row-message__container`} onClick={onClick}>
        <Col className="row-message__participant-col">
          <div style={{ position: "relative" }}>
            <Avatar
              src={participant.avatarUrl}
              size={60}
              style={{
                height: "auto",
              }}
            />
            {participant.isPremium && (
              <span
                className="diamond"
                style={{ position: "absolute", top: 0, right: 0 }}
              >
                <CrownOutlined />
              </span>
            )}
          </div>
          <div className="row-message__participant-info">
            <div className="row-message__participant-name">
              @{participant.participantName}
            </div>

            <div className="row-message__context-info">
              <span className="row-message__venue-address">
                <LocationIcon className="icon" />
                {chat.context.venue?.address ?? "-"}
              </span>
            </div>
          </div>
        </Col>
        <Col className="row-message__message-col">
          <div className="row-message__message-content">
            {lastMessage ? lastMessage.content : <i>No messages yet</i>}
          </div>
          <div className="row-message__badge">
            <Badge
              count={chat.unreadCount}
              style={{ backgroundColor: "#ffac39" }}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};
